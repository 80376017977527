import React from "react";
import { routes } from "../routes";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InventoryIcon from "@mui/icons-material/Inventory";
import AssessmentIcon from "@mui/icons-material/Assessment";

const NavbarButton = () => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      {routes
        .filter((item) => {
          return (
            typeof item.icon !== "undefined" || typeof item.text !== "undefined"
          );
        })
        .map((item, index) => (
          <ListItemButton key={index} href={item.path}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <ListAltIcon />
        </ListItemIcon>
        <ListItemText primary="Inventory" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} href={"/stock"}>
            <ListItemIcon>
              <InventoryIcon />
            </ListItemIcon>
            <ListItemText primary="Inventory Stock" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} href={"/products"}>
            <ListItemIcon>
              <InventoryIcon />
            </ListItemIcon>
            <ListItemText primary="Inventory Product" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton href={"/report"}>
        <ListItemIcon>{<AssessmentIcon />}</ListItemIcon>
        <ListItemText primary={"Reports"} />
      </ListItemButton>
    </>
  );
};
export default NavbarButton;
