import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { styled } from "@mui/material/styles";

const WidgetCard = ({ title, icon, content, color }) => {
  return (
    <Card
      sx={{
        backgroundImage: `linear-gradient(to right, white, ${color})`,
      }}
    >
      <CardHeader
        title={title}
        avatar={icon}
        action={
          <IconButton color="inherit">
            <QueryStatsIcon />
          </IconButton>
        }
        titleTypographyProps={{ variant: "h5", fontWeight: "bold" }}
      />
      <CardContent>
        <Typography variant="h5" component="p">
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default WidgetCard;
