const ImageCell = ({ value }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10%",
        overflow: "hidden",
        width: 100,
        height: 100,
      }}
    >
      <img
        src={value}
        alt="Product"
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
      />
    </div>
  );
};

export default ImageCell;
