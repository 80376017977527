import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Copyright } from "../../components/Copyright";
import { forgotPassword } from "../../services/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ForgotPasswordPage = () => {
  const isXs = useMediaQuery("(max-width:600px)");
  const [username, setUsername] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setUsername(e.target.value);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword(username).then((res) => {
        if (res && res.data.status === 1) {
          toast.success(res.data.message);
          navigate(`/redirect/${username}`);
        } else {
          toast.error(res.data.message);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isXs ? (
              <Typography component="h1" variant="h6" fontWeight="bold">
                Forgot Password?
              </Typography>
            ) : (
              <Typography component="h1" variant="h5" fontWeight="bold">
                Forgot Password?
              </Typography>
            )}
            <Typography>OTP will be sent to your email.</Typography>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                fullWidth
                name="username"
                label="Enter Email Address"
                value={username}
                onChange={handleChange}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={username === ""}
              >
                Submit
              </Button>
            </Box>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPasswordPage;
