import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCujFQEyV6Rz1J-91kDy3nEm-3PNuGUvCM",
  authDomain: "lems-1f0bd.firebaseapp.com",
  projectId: "lems-1f0bd",
  storageBucket: "lems-1f0bd.appspot.com",
  messagingSenderId: "71621281812",
  appId: "1:71621281812:web:55ae4e8c120107c48b3f01",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
