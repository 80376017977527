import React from "react";
import DashboardContent from "./components/DashboardContent";
import { Navigate, Route, Routes } from "react-router-dom";
import { routes, otherRoutes } from "./routes";
import Login from "./pages/login/Login";
import ForgotPasswordPage from "./pages/login/ForgotPasswordPage";
import Register from "./pages/register/Register";
import { getCurrentUser } from "./services/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RedirectPage from "./pages/login/RedirectPage";

const App = () => {
  const getRoutes = () => {
    return routes.map((route) => {
      return (
        <Route key={route.path} path={route.path} element={route.element} />
      );
    });
  };

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={
            getCurrentUser() ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/" element={<DashboardContent />}>
          {getRoutes()}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/redirect/:email" element={<RedirectPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default App;
