import React, { useState } from "react";
import {
  Card,
  Box,
  Typography,
  Button,
  TextField,
  Container,
  Grid,
  CircularProgress,
  CardMedia,
  CardContent,
} from "@mui/material";
import { storage } from "../../services/firebase/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";

const ServiceForm = ({ InitialState, onSubmit }) => {
  const [state, setState] = useState(
    InitialState || {
      type: "",
      service: "",
      price: 0,
      description: "",
      file: null,
    }
  );
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (file) {
      // handleUpload();
      onSubmit(state);
    } else {
      onSubmit(state);
    }
  };

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    setState({ ...state, file: uploadedFile });
  };

  // const handleUpload = () => {
  //   if (!file) return;
  //   const storageRef = ref(storage, `periwinkleServices/${file.name}`);
  //   const uploadTask = uploadBytesResumable(storageRef, file);
  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       const progress = Math.round(
  //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //       );
  //       console.log(progress);
  //     },
  //     (error) => {
  //       alert(error);
  //     },
  //     () => {
  //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //         const updatedForm = {
  //           ...state,
  //           imageUrl: downloadURL,
  //         };
  //         onSubmit(updatedForm);
  //       });
  //     }
  //   );
  // };

  return (
    <Container maxWidth="100%">
      <Typography fontWeight="bold" variant="h5" mb={2}>
        {InitialState === undefined || InitialState === null ? "Add" : "Edit"}{" "}
        Service
      </Typography>
      <Card sx={{ padding: "20px" }}>
        <CardContent component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Type"
                variant="outlined"
                name="type"
                value={state.type}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Services"
                variant="outlined"
                name="service"
                value={state.service}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Price"
                variant="outlined"
                name="price"
                value={state.price}
                onChange={handleChange}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Description"
                variant="outlined"
                name="description"
                value={state.description}
                onChange={handleChange}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <label>Image</label>
              <TextField
                variant="outlined"
                name="file"
                onChange={handleFileUpload}
                type="file"
                fullWidth
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <div style={{ width: "50px" }}>
                  <CircularProgress size={20} />
                </div>
              ) : (
                <>{InitialState ? "Edit" : "Add"}</>
              )}
            </Button>

            <Button
              variant="contained"
              sx={{
                ml: 1,
              }}
              onClick={() => {
                navigate("/stock");
              }}
              color="error"
            >
              Cancel
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ServiceForm;
