import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Copyright } from "../../components/Copyright";
import { useNavigate, useParams } from "react-router-dom";
import { changePass } from "../../services/auth";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Joi from "joi";

const RedirectPage = () => {
  const params = useParams();
  const [form, setForm] = useState({
    newPassword: "",
    confirmPassword: "",
    otp: "",
  });

  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    otp: Joi.string().required().label("OTP"),
    newPassword: Joi.string()
      .min(8)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_])[a-zA-Z\d!@#$%^&*_]{8,}$/
      )
      .required(),
    confirmPassword: Joi.string().allow("").optional(),
  });

  const handleChange = (e) => {
    e.preventDefault();
    setForm({ ...form, [e.target.name]: e.target.value });

    const { error } = schema
      .extract(e.target.name)
      .label(e.target.name.toUpperCase())
      .validate(e.target.value);

    if (error) {
      const passwordErrorMessage =
        e.target.name === "newPassword"
          ? "Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter, one special character, no spaces and one digit."
          : error.details[0].message;
      setErrors({
        ...errors,
        [e.target.name]: passwordErrorMessage,
      });
    } else {
      delete errors[e.target.name];
      setErrors(errors);
    }
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      changePass(params.email, form.otp, { password: form.newPassword }).then(
        (res) => {
          if (res && res.data.status === 1) {
            toast.success(res.data.message);
            navigate("/login");
          } else {
            toast.error(res.data.message);
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const isDisabled = () => {
    return form.newPassword === "" || form.newPassword !== form.confirmPassword;
  };

  const isMatch = () => {
    return form.newPassword !== form.confirmPassword;
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showCPassword, setShowCPassword] = useState(false);

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>Change your password.</Typography>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                fullWidth
                name="otp"
                label="Enter The OTP has been sent to your email"
                value={form.otp}
                onChange={handleChange}
                type="text"
                error={!!errors.otp}
                helperText={errors.otp}
              />
              <TextField
                margin="normal"
                fullWidth
                name="newPassword"
                label="Enter Your New Password"
                value={form.newPassword}
                onChange={handleChange}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.newPassword}
                helperText={errors.newPassword}
              />
              <TextField
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                value={form.confirmPassword}
                onChange={handleChange}
                type={showCPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowCPassword(!showCPassword)}
                        edge="end"
                      >
                        {showCPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={isMatch()}
                helperText={
                  isMatch() && "Please make sure your passwords match"
                }
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isDisabled()}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default RedirectPage;
