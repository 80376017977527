import http from "./http";

export function getAllAppointments() {
  return http.get("appointment/all");
}

export function editStatus(id, body) {
  console.log(body);
  return http.patch(`appointment/editStatus/${id}`, body);
}
