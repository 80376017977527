import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import SuspenseFallback from "./components/SuspenseFallback";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e91e63",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<SuspenseFallback />}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Suspense>
    </ThemeProvider>
  </BrowserRouter>
);
