import http from "./http";

export function getAllServices() {
  return http.get("/service/all");
}
export function addServices(data) {
  return http.post("/service/add", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function editServices(id, data) {
  return http.patch(`/service/edit/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getServicesbyID(id) {
  return http.get(`/service/${id}`);
}

export function deleteServices(id) {
  return http.delete(`/service/delete/${id}`);
}
