import React, { useEffect, useState } from "react";
import { getAllServices } from "../../services/services";
import { Card, Container, Typography, Grid, Button } from "@mui/material";
import ServicesTable from "./ServicesTable";
import SuspenseFallback from "../../components/SuspenseFallback";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const Services = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    getAllServices().then((res) => {
      if (res && res.data) {
        setData(res.data.object);
      }
    });
  };

  if (data) {
    return (
      <Container maxWidth="100">
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5" mb={2} fontWeight="bold">
              Services
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="end">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/services/add");
              }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Card sx={{ padding: "20px" }}>
          <ServicesTable
            data={data.sort(
              (a, b) => new Date(b.date_modified) - new Date(a.date_modified)
            )}
            setData={setData}
          />
        </Card>
      </Container>
    );
  }
  return <SuspenseFallback />;
};

export default Services;
