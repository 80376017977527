import http from "./http";

export function getSalesReport() {
  return http.get("/order/sales/dashboard");
}

export function getListSalesReport(date1, date2) {
  console.log(date1, date2);
  return http.get(`/report/generate/${date1}/${date2}`);
}
